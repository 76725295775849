
import Courses1 from "../assets/BA.png";
import Courses2 from "../assets/PM.png";
import Courses3 from "../assets/SM.png";
import Courses4 from "../assets/CS.png";
import Courses5 from "../assets/SE.png";
import Courses6 from "../assets/DA.png";
import Courses7 from "../assets/Product Management.png";
import tutor from '../assets/avatar2.jpeg';
import student2 from '../assets/avatar2.jpeg';


export const courses = [
    {
        id: 1,
        name: 'Business Analysis',
        price: '€370 - €750',
        image: Courses1,
        students: '45 enrolled',
        description: 'Shape The Future With In-Demand Business Analysis Skills!',
        tutor: 'Kingsley',
        lastUpdated: '1/2025',
        aboutThisCourse: 'Become an expert Business Analyst in 12-16 weeks with Kayrex Academy! Our comprehensive, 2025-ready program is tailored to provide aspiring and experienced professionals with cutting-edge tools, techniques, and frameworks to thrive in today’s competitive landscape.',
        programFeatures:[

            { title: 'Interactive Virtual Classes:',
           details:'Flexible 3- or 6-month schedules with hands-on practical projects.',
            },
             {title: 'Industry Certifications:',
             details:'Assistance in achieving certifications like CBAP, CCBA, PMI-PBA, and Agile Business Analyst',
            },
            {title: 'Career Support and Consulting Services:',
             details:['Free professional CV assistance and interview preparation.',
             'Lifetime access to Kayrex’s repository of learning materials.'],
             },
     
             ],
        curriculumOverview: [
            {
                category: 'Core Topics',
                title: 'Elicitation and Stakeholder Engagement',
                details: [
                    'Interviews, focus groups, workshops, and surveys to gather business requirements.',
                    'Stakeholder management techniques to ensure alignment and collaboration.',
                ],
            },
            {
                category: 'Core Topics',
                title: 'Requirements Documentation and Validation',
                details: [
                    'Crafting Use Cases, User Stories, Business Requirements Documents (BRDs), and Functional Specifications.',
                    'Validation techniques such as MoSCoW and Kano Model for prioritization.',
                ],
            },
            {
                category: 'Core Topics',
                title: 'Process Modeling and Analysis',
                details: [
                    'Visualizing business processes using BPMN 2.0 and SIPOC.',
                    'Identifying bottlenecks and improvement opportunities in workflows.',
                ],
            },
            {
                category: 'Core Topics',
                title: 'Unified Modeling Language (UML)',
                details: [
                    'Creating Class, Sequence, and Activity Diagrams for system design.',
                ],
            },
            {
                category: 'Core Topics',
                title: 'Agile Business Analysis',
                details: [
                    'Agile principles and practices: Scrum and Kanban.',
                    'Writing and managing backlog items and acceptance criteria.',
                ],
            },
            {
                category: 'Core Topics',
                title: 'User Acceptance Testing (UAT)',
                details: [
                    'Designing and executing test cases.',
                    'Collaborating with QA teams to ensure seamless implementation.',
                ],
            },
            {
                category: 'Advanced and Emerging Topics',
                title: 'Digital Transformation and Change Management',
                details: [
                    'Supporting organizations in adapting to modern digital tools and solutions.',
                    'Change impact assessment and stakeholder buy-in techniques.',
                ],
            },
            {
                category: 'Advanced and Emerging Topics',
                title: 'Data-Driven Business Analysis',
                details: [
                    'Leveraging tools like Tableau, Power BI, and Python for data visualization and decision-making.',
                    'Basics of Predictive Analytics and Data Storytelling.',
                ],
            },
            {
                category: 'Advanced and Emerging Topics',
                title: 'AI and Automation in Business Analysis',
                details: [
                    'Writing requirements for AI-driven and automated solutions.',
                    'Identifying automation opportunities in business processes.',
                ],
            },
            {
                category: 'Advanced and Emerging Topics',
                title: 'Cloud and SaaS Systems',
                details: [
                    'Analyzing requirements for cloud-based applications and SaaS platforms.',
                    'Migration and integration planning for legacy systems.',
                ],
            },
            {
                category: 'Advanced and Emerging Topics',
                title: 'Customer Experience (CX) and User Experience (UX) Analysis',
                details: [
                    'Mapping customer journeys and building empathy maps.',
                    'Collaborating with UX teams to create prototypes using tools like Figma and Adobe XD.',
                ],
            },
            {
                category: 'Advanced and Emerging Topics',
                title: 'Cybersecurity Awareness for Business Analysts',
                details: [
                    'Writing security-focused requirements.',
                    'Frameworks like GDPR and NIST Cybersecurity.',
                ],
            },
            {
                category: 'Advanced and Emerging Topics',
                title: 'Blockchain in Business Analysis',
                details: [
                    'Analyzing blockchain use cases in industries like finance, healthcare, and supply chain.',
                    'Writing smart contract requirements and ensuring process transparency.',
                ],
            },
            {
                category: 'Advanced and Emerging Topics',
                title: 'Agile at Scale',
                details: [
                    'Business Analysis within SAFe (Scaled Agile Framework) and LeSS (Large-Scale Scrum).',
                    'Managing enterprise-level backlogs and multiple teams.',
                ],
            },
            {
                category: 'Advanced and Emerging Topics',
                title: 'Sustainability and Green Business Analysis',
                details: [
                    'Assessing environmental impacts of business initiatives.',
                    'Developing business solutions aligned with sustainability goals.',
                ],
            },
         
        ],
        practicalTools:
        [
            'Collaboration Tools: Jira, Confluence, Miro, Smartsheet.',
             'Modeling Tools: Lucidchart, Microsoft Visio, Draw.io.',
             'Analytics Tools: Tableau, Power BI, Python (basic scripting).',
             'Process Automation: UiPath, Blue Prism, Automation Anywhere.',

        ],
        careers:[

            { title: 'High-Demand Roles You Can Secure:',
           details:[
            'Business Analyst',
           'Product Owner',
            'Data Analyst',
            'Agile Analyst',
            'Process Improvement Specialist',
            'Requirements Engineer'],
            },
             {title: 'Earning Potential and Global Opportunities:',
             details: ['Start with a minimum salary of €60,000.',
             'Visa sponsorship opportunities for roles in Ireland, UK, US, Canada, and Australia.',
               ],
            },
     
             ],
   
        instructor: {
            name: 'Kingsley',
            photo: tutor, 
            bio:'With over six years of experience as a Business Analyst, I specialize in delivering innovative solutions by bridging business objectives with technical requirements. My expertise includes Agile and Waterfall methodologies, requirement gathering, stakeholder engagement, and producing functional and technical documentation.I am passionate about helping students master the core skills of business analysis, from crafting justifiable business cases to collaborating with technical teams for project success. My teaching focuses on practical, real-world applications to prepare you for the challenges of a dynamic business environment.',
            stats: {
              studentsTaught: '50+',
              experience: '6+ ',
              courses: '10+',
              review: '5'
            },
          },
          testimonials: [
            {
                name: 'Ngozi M',
                initial: 'NM',
                rating: 5,
                text: 'I transitioned into tech seamlessly thanks to their comprehensive Business Analysis course!',
                image: student2,
            },
            {
                name: 'Zainab K.',
                initial: 'ZK',
                rating: 4,
                text: 'I now lead a team of analysts, thanks to the skills I learned at Kayrex Academy.',
                image: student2,
            },
            {
                name: 'Jane Smith',
                initial: 'JS',
                rating: 4,
                text: 'I now work in one of the top tech firms in Europe, thanks to Kayrex Academy.',
                image: student2,
            }
        ],
  
    },
    {
        id: 2,
        name: 'Project Management',
        price: '€370 - €750',
        image: Courses2,
        students: '35 enrolled',
        description: 'Master Project Management Principles And Tools.',
        tutor: 'Ola ',
        lastUpdated: '1/2025',
        aboutThisCourse: 'At Kayrex Academy, we empower individuals to master the art and science of Project Management through globally recognized training 10-14 weeks. Whether you are new to the field or an experienced professional, our comprehensive program is tailored to provide you with the tools, knowledge, and confidence to excel.',
        programFeatures: [
            {
                title: 'What You Gain:',
                details: [
                    'Earn certifications recognized worldwide, with potential starting salaries from €60,000 annually.',
                    'Job placement support, including visa sponsorship opportunities.',
                    'Hands-on experience with industry-standard tools and frameworks.',
                    'Flexible payment plans and scholarship options.',
                    'Lifetime access to Kayrex resources and mentorship.',
                ],
            },
            {
                title: 'Key Benefits for Kayrex Students:',
                details: [
                    'Certifications: Globally recognized credentials to boost your career.',
                    'Hands-On Projects: Real-world projects to build your confidence and portfolio.',
                    'Career Support: Comprehensive job referrals, interview prep, and post-placement support.',
                    'Flexible Learning: Installment payment plans and scholarships to make learning accessible.',
                    'Lifetime Access: Continue learning with unlimited access to resources and mentorship.',
                ],
            },
        ],
        curriculumOverview: [
            {
                title: 'Foundations of Project Management',
                details: [
                    'Introduction to Project Management: Understanding roles, responsibilities, and key concepts.',
                    'Project Life Cycle: Initiation, planning, execution, monitoring, and closure.',
                    'Project Management Methodologies: Waterfall, Agile (Scrum, Kanban, SAFe), and Hybrid approaches.',
                    'PMBOK Framework: Overview of Project Management Institute (PMI) standards.',
                ],
            },
            {
                title: 'Tools and Techniques',
                details: [
                    'Trello: Managing tasks and workflows effectively.',
                    'Microsoft Project: Comprehensive project planning and scheduling.',
                    'Gantt Charts: Visualizing project timelines and dependencies.',
                    'Kanban Boards: Streamlining workflows and improving team collaboration.',
                ],
            },
            {
                title: 'Work Breakdown Structure (WBS)',
                details: [
                    'Creating a WBS: Breaking down projects into manageable tasks.',
                    'Task Dependencies: Sequencing tasks for efficiency.',
                    'Resource Allocation: Assigning tasks to maximize productivity.',
                ],
            },
            {
                title: 'Critical Path Method (CPM)',
                details: [
                    'Identifying the Critical Path: Understanding task durations and project timelines.',
                    'Slack and Float Analysis: Managing delays without affecting deadlines.',
                    'Risk Management: Identifying and mitigating project risks.',
                ],
            },
            {
                title: 'Risk Management and Analysis',
                details: [
                    'Risk Matrices: Prioritizing and categorizing risks.',
                    'Mitigation Strategies: Proactive planning for potential issues.',
                    'Communication Plans: Keeping stakeholders informed.',
                ],
            },
            {
                title: 'Agile Project Management',
                details: [
                    'Scrum Framework: Sprint planning, daily stand-ups, and retrospectives.',
                    'Kanban Methods: Visualizing workflows and optimizing throughput.',
                    'SAFe Principles: Scaling Agile for large teams and enterprises.',
                    'Hands-On Practice: Simulating Agile projects using Trello or Jira.',
                ],
            },
            {
                title: 'Budgeting and Financial Management',
                details: [
                    'Budget Creation: Estimating costs and resources.',
                    'Cost Control Techniques: Monitoring expenditures and avoiding overruns.',
                    'Earned Value Management (EVM): Analyzing project performance.',
                ],
            },
            {
                title: 'Leadership and Team Collaboration',
                details: [
                    'Leadership Styles: Adapting to different team dynamics.',
                    'Conflict Resolution: Managing disagreements effectively.',
                    'Cross-Functional Collaboration: Enhancing communication across departments.',
                    'Stakeholder Engagement: Keeping stakeholders aligned with project goals.',
                ],
            },
            {
                title: 'Real-World Projects',
                details: [
                    'Work on live projects and case studies across industries such as technology, healthcare, and finance.',
                    'Develop comprehensive project plans, Gantt charts, and risk assessments.',
                    'Present final project deliverables to mentors and peers for feedback.',
                ],
            },
            {
                title: 'Career Readiness and Soft Skills Development',
                details: [
                    'Interview Preparation: Mock interviews and scenario-based questions.',
                    'CV Optimization: Crafting a project management-focused CV.',
                    'LinkedIn Optimization: Creating a profile that attracts recruiters.',
                    'Communication Skills: Sharpening presentation and negotiation skills.',
                ],
            },
        ],
        careers: [
            {
                title: 'High-Demand Roles You Can Secure:',
                details: [
                    'Project Manager',
                    'Program Manager',
                    'Agile Coach',
                    'Business Analyst',
                    'Operations Manager',
                ],
            },
            {
                title: 'Earning Potential and Global Opportunities:',
                details: [
                    'Potential employers span industries like technology, construction, healthcare, and finance, with starting salaries ranging from €60,000 to €85,000 annually (with visa sponsorship opportunities).',
                ],
            },
        ],
        instructor: {
            name: 'Ola',
            photo: tutor, 
            bio: 'Ola is a seasoned Project Management professional with 7 years of experience delivering successful projects across industries. Specializing in Agile and Waterfall methodologies, Ola equips students with hands-on skills in tools like Trello, Microsoft Project, and risk management techniques.With a passion for mentoring and real-world insights, Ola simplifies complex concepts to prepare students for successful project management careers.Join Ola’s class at Kayrex Academy and kickstart your journey to becoming a certified project manager!',
            stats: {
              studentsTaught: '70+',
              experience: '7+',
              courses: '15+',
             review: '5'
            },
          },
          testimonials: [
            {
                name: 'Ibrahim S.',
                initial: 'IS',
                rating: 5,
                text: 'Their focus on practical skills is what sets Kayrex apart from other academies.',
                image: student2,
            },
            {
                name: 'Funmi A.',
                initial: 'FA',
                rating: 4,
                text: 'Their project management training was detailed and well-structured. Highly recommend!',
                image: student2,
            }
        ],
    },
    {
        id: 3,
        name: 'Scrum Master',
        price: '€370 - €750',
        image: Courses3,
        students: '55 enrolled',
        description: 'Become A Certified Scrum Master And Lead Agile Projects.',
        tutor: 'Akinsola',
        lastUpdated: '1/2025',
        aboutThisCourse: 'Kayrex Academy is dedicated to preparing professionals to excel in Agile environments. Our Scrum Master program is designed to equip you with both foundational and advanced knowledge of Scrum principles, ensuring you can confidently lead Agile teams and deliver high-quality results in 10-14 weeks.',
        programFeatures: [
            {
                title: 'What You Gain:',
                details: [
                    'Earn two globally recognized Scrum Master certifications.',
                    'Gain practical experience with real-world Scrum scenarios.',
                    'Access job opportunities with starting salaries from €60,000 annually.',
                    'Visa sponsorship options for international roles.',
                    'Flexible payment plans and lifetime access to resources.',
                ],
            },
            {
                title: 'Key Benefits for Kayrex Students:',
                details: [
                    'Certifications: Two globally recognized Scrum Master certifications.',
                    'Hands-On Projects: Real-world Scrum projects for practical experience.',
                    'Career Support: Job referrals, interview prep, and resume reviews.',
                    'Lifetime Access: Continuous learning and mentorship resources.',
                    'Flexible Payment Plans: Affordable and accessible payment options.',
                ],
            },
        ],
        curriculumOverview: [
            {
                title: 'Foundations of Scrum and Agile',
                details: [
                    'Introduction to Agile Principles: Understanding Agile methodologies and their benefits.',
                    'Scrum Framework: Overview of Scrum roles, artifacts, and ceremonies.',
                    'Scrum Values: Applying principles of commitment, focus, openness, respect, and courage.',
                    'Key Tools: Familiarization with tools like Jira, Trello, and Agile management platforms.',
                ],
            },
            {
                title: 'Scrum Roles and Responsibilities',
                details: [
                    'Scrum Master: Facilitating and coaching Agile teams.',
                    'Product Owner: Collaborating on product vision and backlog prioritization.',
                    'Development Team: Understanding team dynamics and cross-functional collaboration.',
                    'Stakeholder Management: Engaging stakeholders to align expectations and deliverables.',
                ],
            },
            {
                title: 'Scrum Artifacts and Ceremonies',
                details: [
                    'Product Backlog Management: Creating, prioritizing, and refining user stories.',
                    'Sprint Planning: Setting sprint goals and planning team capacity.',
                    'Daily Stand-ups: Facilitating effective team discussions.',
                    'Sprint Reviews and Retrospectives: Continuous improvement through feedback.',
                ],
            },
            {
                title: 'Project Management Skills',
                details: [
                    'Agile Project Planning: Breaking down projects into sprints.',
                    'Risk Mitigation: Identifying and addressing project risks.',
                    'Resource Allocation: Managing team resources effectively.',
                    'Time Management: Ensuring timely delivery of project goals.',
                ],
            },
            {
                title: 'Technical Knowledge in Software Development',
                details: [
                    'Scrum in Software Development: Integrating Scrum with software delivery pipelines.',
                    'DevOps Practices: Understanding Continuous Integration and Continuous Deployment (CI/CD).',
                    'Collaborating with Development Teams: Enhancing communication and removing impediments.',
                ],
            },
            {
                title: 'Tools and Techniques for Scrum Management',
                details: [
                    'Jira and Trello: Managing sprints and backlogs.',
                    'Kanban Boards: Visualizing workflows to improve team efficiency.',
                    'Scrum Metrics: Tracking velocity, burndown charts, and cumulative flow diagrams.',
                ],
            },
            {
                title: 'Continuous Learning and Certification Opportunities',
                details: [
                    'Mock Assessments: Preparing for Scrum Master certification exams.',
                    'Exam Strategies: Tips and tricks for passing certification tests.',
                    'Additional Certifications: Guidance on certifications like SAFe Scrum Master and PMI-ACP.',
                ],
            },
            {
                title: 'Practical Application of Scrum in Teams',
                details: [
                    'Real-World Simulations: Facilitating mock sprints and Agile ceremonies.',
                    'Team Dynamics: Building high-performing Agile teams.',
                    'Conflict Resolution: Addressing challenges within teams.',
                    'Case Studies: Applying Scrum practices in various industries.',
                ],
            },
            {
                title: 'Organizational Skills for Effective Team Management',
                details: [
                    'Leadership Styles: Adapting your approach to different team needs.',
                    'Stakeholder Communication: Keeping stakeholders informed and engaged.',
                    'Decision-Making: Using data-driven methods to guide team progress.',
                    'Cultural Awareness: Managing Agile teams across different geographies and cultures.',
                ],
            },
        ],
        careers: [
            {
                title: 'Career Opportunities After Completion:',
                details: [
                    'Scrum Master',
                    'Agile Coach',
                    'Project Manager',
                    'Product Owner',
                    'Delivery Manager',
                ],
            },
            {
                title: 'Earning Potential and Global Opportunities:',
                details: [
                    'Starting salaries range from €60,000 to €85,000 annually.',
                    'Industries: Technology, finance, healthcare, and consulting.',
                    'Visa sponsorship options for global roles.',
                ],
            },
        ],
        instructor: {
            name: 'Akinsola',
            photo: tutor, 
            bio: 'A Technical Product Manager and Scrum Master with over five years of experience delivering innovative solutions in EdTech and FinTech space. As a trainer, being equipped with PSPO II, PMP, MBA -  I specialize in Business Analysis, Product Management, and Scrum, equipping professionals with actionable skills. My approach blends technical acumen with strategic thinking, ensuring participants gain practical, actionable insights.I excel in bridging business needs with technology, leading cross-functional teams, and driving impactful results.',
            stats: {
              studentsTaught: '60+',
              experience: '5+',
              courses: '10+',
              review: '5'
            },
          },
          testimonials: [
            {
                name: 'Adebola A',
                initial: 'AA',
                rating: 5,
                text: 'The Scrum Master course gave me the confidence to lead agile teams effectively. I couldn’t have done it without Kayrex',
                image: student2,
            },
            {
                name: 'Mary J',
                initial: 'MJ',
                rating: 4,
                text: 'My confidence skyrocketed after completing the Agile Methods training.',
                image: student2,
            }
        ],
    },
    
    {
        id: 4,
        name: 'Cyber Security',
        price: '€450 - €750',
        image: Courses4,
        students: '45 enrolled',
        description: 'Future-Proof Your Career With Cutting-Edge Cyber Security Skills!.',
        tutor: 'Jim',
        lastUpdated: '1/2025',
        aboutThisCourse: 'Cyber Security remains at the forefront of protecting businesses, governments, and individuals in the digital age. Our program is tailored to the latest industry trends, ensuring you gain the skills that are essential for tomorrow`s workforce 14-18 weeks. With job opportunities starting at €60,000, this program is your pathway to a lucrative career.',
        programFeatures: [
            {
                title: 'Interactive Virtual Classes:',
                details: 'Flexible 3- or 6-month schedules with hands-on practical projects.',
            },
            {
                title: 'Industry Certifications:',
                details: 'Assistance in achieving certifications like CBAP, CCBA, PMI-PBA, and Agile Business Analyst.',
            },
            {
                title: 'Career Support and Consulting Services:',
                details: [
                    'Free professional CV assistance and interview preparation.',
                    'Lifetime access to Kayrex’s repository of learning materials.',
                ],
            },
        
            {
                title: 'Hands-On Experience with Industry Tools:',
                details: 'Gain expertise in over 20 industry-relevant tools including Splunk, Docker, Metasploit, Prisma Cloud, and more.',
            },
            {
                title: 'Mastery of Emerging Tech:',
                details: 'Learn to secure AI, blockchain, IoT, and quantum technologies.',
            },
            {
                title: 'Certification Support:',
                details: [
                    'Certified Information Systems Security Professional (CISSP)',
                    'Certified Ethical Hacker (CEH)',
                    'AWS Certified Security - Specialty',
                    'GIAC Penetration Tester (GPEN)',
                ],
            },
            {
                title: 'Comprehensive Career Support:',
                details: [
                    'Personalized career coaching and interview preparation.',
                    'Networking opportunities with tech industry leaders.',
                ],
            },
        ],
        
        curriculumOverview: [
            {
                category: 'Core Topics',
                title: 'Email Analysis',
                details: [
                    'Advanced email threat detection and response.',
                    'Anti-phishing protocols and ransomware mitigation.',
                    'Tools: MXToolbox, Mimecast, Barracuda.',
                ],
            },
            {
                category: 'Core Topics',
                title: 'Network Security',
                details: [
                    'Zero-trust network architecture.',
                    'Advanced threat monitoring and intrusion detection systems (IDS).',
                    'Tools: Cisco Firepower, Suricata, Palo Alto Networks.',
                ],
            },
            {
                category: 'Core Topics',
                title: 'Log Analysis and Threat Intelligence',
                details: [
                    'Automated log analysis using AI and ML algorithms.',
                    'Real-time threat intelligence integration.',
                    'Tools: Splunk, Elastic SIEM, Graylog.',
                ],
            },
            {
                category: 'Core Topics',
                title: 'Vulnerability Analysis and Threat Hunting',
                details: [
                    'AI-driven vulnerability scanning and predictive threat hunting.',
                    'Integrating vulnerability management into DevSecOps pipelines.',
                    'Tools: Tenable.io, Nessus, Qualys.',
                ],
            },
            {
                category: 'Core Topics',
                title: 'Cloud Security',
                details: [
                    'Multi-cloud environment security (AWS, Azure, GCP).',
                    'Securing containers, Kubernetes, and serverless applications.',
                    'Tools: Prisma Cloud, AWS Security Hub, Aqua Security.',
                ],
            },
            {
                category: 'Core Topics',
                title: 'Web Penetration Testing',
                details: [
                    'Exploiting and securing APIs and microservices.',
                    'Mitigating OWASP’s latest top 10 vulnerabilities (2025 update).',
                    'Tools: Burp Suite, OWASP ZAP, Postman.',
                ],
            },
            {
                category: 'Core Topics',
                title: 'OS and Network Penetration Testing',
                details: [
                    'Advanced red teaming and ethical hacking techniques.',
                    'Emerging network vulnerabilities in IoT and edge devices.',
                    'Tools: Kali Linux, Metasploit, Nmap, Aircrack-ng.',
                ],
            },
            {
                category: 'Core Topics',
                title: 'Network Analysis and Forensics',
                details: [
                    'Post-incident analysis and recovery.',
                    'Handling advanced persistent threats (APT).',
                    'Tools: Wireshark, SolarWinds, Zeek.',
                ],
            },
            {
                category: 'Future-Focused Topics',
                title: 'Cybersecurity for AI and Machine Learning Systems',
                details: [
                    'Identifying adversarial attacks on AI models.',
                    'Securing AI-driven applications in finance, healthcare, and IoT.',
                ],
            },
            {
                category: 'Future-Focused Topics',
                title: 'Quantum-Safe Cryptography',
                details: [
                    'Preparing for post-quantum cryptography challenges.',
                    'Implementing quantum-safe encryption algorithms.',
                ],
            },
            {
                category: 'Future-Focused Topics',
                title: 'IoT and IIoT Security (Internet of Things)',
                details: [
                    'Addressing security in smart homes, factories, and cities.',
                    'Vulnerability management in Industrial IoT (IIoT).',
                    'Tools: Azure IoT Security, Armis.',
                ],
            },
            {
                category: 'Future-Focused Topics',
                title: 'Blockchain and Web3 Security',
                details: [
                    'Securing blockchain networks, wallets, and DeFi applications.',
                    'Analyzing and preventing crypto-jacking and smart contract vulnerabilities.',
                ],
            },
            {
                category: 'Future-Focused Topics',
                title: 'Governance, Risk, and Compliance (GRC)',
                details: [
                    'Navigating global regulations (e.g., GDPR, CCPA, NIST frameworks).',
                    'Auditing and risk assessment for businesses.',
                    'Tools: RSA Archer, LogicGate.',
                ],
            },
            {
                category: 'Future-Focused Topics',
                title: 'Cybersecurity for Critical Infrastructure',
                details: [
                    'Protecting energy, transportation, and healthcare systems.',
                    'Security measures for SCADA systems and OT networks.',
                ],
            },
            {
                category: 'Future-Focused Topics',
                title: 'DevSecOps and CI/CD Security',
                details: [
                    'Embedding security into agile and DevOps workflows.',
                    'Automated scanning of CI/CD pipelines.',
                    'Tools: Snyk, SonarQube, GitLab Security.',
                ],
            },
            {
                category: 'Future-Focused Topics',
                title: 'Ransomware Defense and Incident Response',
                details: [
                    'Advanced ransomware attack simulations.',
                    'Creating effective incident response playbooks.',
                ],
            },
        ],
        careers:[

            { title: 'High-Demand Roles You Can Secure:',
           details:[
            'Cyber Security Architect',
            'SOC Engineer',
            'Cloud Security Consultant',
            'Threat Intelligence Analyst',
            'IoT Security Specialist',
            'Penetration Tester',
        ],
    },
             ],
  
    
        instructor: {
            name: 'Jim',
            photo: tutor, 
            bio: 'With over 8 years of experience as a Cybersecurity Specialist and Criminal Intelligence Analyst, I specialize in safeguarding organizations against cyber threats and enhancing security infrastructure. I bring expertise in vulnerability assessments, secure network architecture, OSINT, and incident response, with a proven track record of reducing security breaches by 40% and improving incident response efficiency.Leveraging my experience in developing actionable intelligence reports for law enforcement, I empower students to understand and counteract cybercrime threats. At Kayrex Academy, I aim to equip learners with proactive security strategies and robust defense skills to excel in the field of cybersecurity.',
            stats: {
              studentsTaught: '90+',
              experience: '8+',
              courses: '6+',
              review: '5',
            },
          },
          testimonials: [
            {
                name: 'Ifeanyi E',
                initial: 'IE',
                rating: 5,
                text: 'Kayrex gave me the tools to excel in my role as a cybersecurity analyst. Thank you for the knowledge.',
                image: student2,
              },
            {
                name: 'Amaka N',
                initial: 'AN',
                rating: 5,
                text: 'I’ve recommended Kayrex Academy to all my friends—it’s the best investment I’ve made in my career.',
                image: student2,
              }
        ],
        
    },
    {
        id: 5,
        name: 'Software Engineering ',
        price: '€370 - €1200',
        image: Courses5,
        students: '25 enrolled',
        description: 'Become A Skilled First Class Software Developer With Kayrex.',
        tutor: 'Ifeanyi',
        lastUpdated: '1/2025',
        aboutThisCourse: 'Kayrex Academy empowers individuals with or without prior experience to excel in Full-Stack Software Engineering. Catering to students and professionals globally (from Ireland, Canada, the UK, the US, Australia, and beyond), our program equips learners with the skills to build robust, scalable, and efficient software solutions for web and mobile platforms in 14-18 weeks',
        programFeatures:
        [
            {
                    title: 'What You Gain:',
                    details: [
            'Earn certifications that validate your expertise and boost your career prospects (starting from €60,000 annually).',
            'Visa sponsorship job options.',
            'Hands-on training with industry-relevant projects.',
            'Lifetime access to Kayrex resources and mentorship.',
            'Flexible payment plans and scholarships available.',
        ],
    },
        {   title:  'Key Benefits for Kayrex Students:',
            details: [
            'Certifications: Globally recognized certifications to showcase your skills.',
            'Hands-On Projects: Gain real-world experience through live projects.',
            'Career Support: Job referrals, referencing, and post-employment support.',
            'Flexible Payment: Installment plans and scholarships to make learning accessible.',
            'Lifetime Access: Continued learning and mentorship through Kayrex resources.',
        ],
            },
        ],

        curriculumOverview: [
            {
                title: 'Foundations of Software Engineering',
                details: [
                    'Introduction to Full-Stack Development: Overview of front-end, back-end, and mobile development.',
                    'Programming Fundamentals: Algorithms, data structures, and object-oriented programming (OOP).',
                    'Version Control: Mastering Git and GitHub for collaboration.',
                ],
            },
            {
                title: 'Front-End Development',
                details: [
                    'Core Technologies: HTML, CSS, and JavaScript (ES6+).',
                    'Frameworks: React.js and Vue.js.',
                    'UI/UX Design: Responsive design, accessibility, and best practices.',
                    'Project: Build a dynamic, responsive website.',
                ],
            },
            {
                title: 'Back-End Development',
                details: [
                    'Server-Side Programming: Node.js and Express.js.',
                    'Database Management: MongoDB (NoSQL) and PostgreSQL/SQL Server (SQL).',
                    'RESTful APIs: Creating, securing, and consuming APIs.',
                    'Authentication: Implementing secure login systems (JWT, OAuth2).',
                    'Project: Build a secure and scalable API.',
                ],
            },
            {
                title: 'Mobile App Development',
                details: [
                    'Frameworks: Flutter for cross-platform mobile development.',
                    'Back-End Integration: Using Firebase for real-time databases and authentication.',
                    'Deployment: Publishing apps on Google Play Store and Apple App Store.',
                    'Project: Develop and deploy a mobile app.',
                ],
            },
            {
                title: 'Advanced Topics',
                details: [
                    'Cloud Computing: Introduction to AWS, Azure, or Google Cloud.',
                    'DevOps Basics: CI/CD pipelines and containerization with Docker.',
                    'Microservices Architecture: Building modular and scalable applications.',
                    'Testing: Unit testing, integration testing, and end-to-end testing.',
                ],
            },
            {
                title: 'Real-Life Project Implementation',
                details: [
                    'Work on live projects simulating industry scenarios.',
                    'Build full-stack applications integrating web and mobile platforms.',
                    'Case studies from industries like finance, healthcare, and e-commerce.',
                ],
            },
            {
                title: 'Soft Skills Development',
                details: [
                    'Interview Preparation: Mock interviews, coding challenges, and feedback.',
                    'CV Optimization: Tailored CV creation for software engineering roles.',
                    'LinkedIn Optimization: Crafting a profile to attract recruiters.',
                    'Team Collaboration: Agile and Scrum methodologies.',
                ],
            },
        ],

        careers:[
            { title: 'High-Demand Roles You Can Secure:',
           details:[
            'Full-Stack Developer',
            'Front-End Developer',
            'Back-End Developer',
            'Mobile App Developer',
            'Software Engineer',
              
        ],
    },
    {title: 'Earning Potential and Global Opportunities:',
        details: [ 'Potential employers include tech startups, multinational corporations, consulting firms, and government agencies, with starting salaries ranging from €60,000 to €80,000 annually (with visa sponsorship opportunities).',
          ],
       },
             ],
        instructor: {
            name: 'Ifeanyi',
            photo: tutor, 
            bio: 'With 9 years of expertise in software engineering and data analytics, I specialize in developing scalable software solutions and leveraging data-driven insights to solve complex business challenges. Proficient in full-stack development, backend architecture, data modeling, and cloud infrastructure management, I guide students in mastering modern technologies such as Python, JavaScript, SQL, React, Django, and cloud platforms like AWS and Azure.  My teaching focuses on practical applications of data pipelines, machine learning integration, and predictive modeling to drive efficiency and growth. Join me at Kayrex Academy to learn how to transform business requirements into innovative technical solutions!',
            stats: {
              studentsTaught: '100+',
              experience: '9+',
              courses: '6+',
              review: '5',
            },
          },
          testimonials: [
            {
                name: 'Bukola T',
                initial: 'BT',
                rating: 5,
                text: 'Kayrex Academy turned my passion into a profession. I’m forever grateful!.',
                image: student2,
            },
            {
                name: 'Chidinma I',
                initial: 'CI',
                rating: 4,
                text: 'The job placement support is phenomenal. I got multiple offers after graduating.',
                image: student2,
              }
        ],
    },
    {
        id: 6,
        name: 'Data Analysis ',
        price: '€300 - €1000',
        image: Courses6,
        students: '25 enrolled',
        description: 'Become A Certified Data Analyst With Kayrex.',
        tutor: 'Philip',
        lastUpdated: '1/2025',
        aboutThisCourse: 'Kayrex Academy is a global tech training powerhouse, equipping individuals with or without prior experience to become proficient Data Analysts. We cater to a diverse audience, including students and professionals from Ireland, Canada, the United Kingdom, the United States, Australia, and beyond. Our comprehensive program ensures job-readiness by blending cutting-edge tools, real-world projects, certifications, and consulting services tailored to industry demands 14-18 weeks.',
        programFeatures: [
           
            {
                title: 'What You Gain:',
                details: [
                    'Earn certifications that open doors to high-paying jobs (starting from €45,000 annually).',
                    'Visa sponsorship job options.',
                    'Lifetime access to Kayrex resources and mentorship.',
                    'Flexible payment plans to suit your financial needs.',
                ],
            },
            {
                title: 'Key Benefits for Kayrex Students:',
                details: [
                    'Certifications: Two globally recognized certifications to validate your expertise.',
                    'Hands-On Projects: Gain experience with live projects, making you industry-ready.',
                    'Job Support: Job referrals, referencing, and ongoing support post-employment.',
                    'Flexible Payment: Easy installment plans.',
                    'Lifetime Access: Continuous learning through our resources.',
                ],
            },
        ],
          curriculumOverview:[
                    {
                        title: 'Foundations of Data Analysis',
                        details: [
                            'Introduction to Data Analysis: Understanding the role of a Data Analyst and career opportunities.',
                            'Data Life Cycle: Data collection, cleaning, processing, analysis, and visualization.',
                            'Basic Statistics: Mean, median, mode, standard deviation, and data distribution concepts.',
                        ],
                    },
                    {
                        title: 'Excel for Data Analysis',
                        details: [
                            'Advanced Excel techniques (pivot tables, macros, and automation).',
                            'Data cleaning and validation.',
                            'Creating dynamic dashboards for reporting.',
                        ],
                    },
                    {
                        title: 'Power Query and Power BI/Tableau',
                        details: [
                            'Data transformation using Power Query.',
                            'Building interactive dashboards in Power BI and Tableau.',
                            'Advanced DAX (Data Analysis Expressions) for creating powerful calculated fields.',
                        ],
                    },
                    {
                        title: 'SQL (Structured Query Language)',
                        details: [
                            'Writing complex SQL queries for data extraction and manipulation.',
                            'Working with PostgreSQL and SQL Server.',
                            'Database design and optimization techniques.',
                        ],
                    },
                    {
                        title: 'Python for Data Analysis',
                        details: [
                            'Introduction to Python and its relevance in data analysis.',
                            'Libraries: Pandas, NumPy, Matplotlib, and Seaborn.',
                            'Automation of data tasks and exploratory data analysis (EDA).',
                        ],
                    },
                    {
                        title: 'Machine Learning Basics',
                        details: [
                            'Introduction to supervised and unsupervised learning.',
                            'Using Python to implement basic machine learning models.',
                            'Real-world use cases: predicting trends, customer segmentation, etc.',
                        ],
                    },
                    {
                        title: 'Real-Life Project Implementation',
                        details: [
                            'Work on live projects that simulate real-world problems.',
                            'Case studies from industries like healthcare, finance, e-commerce, and technology.',
                            'End-to-end project presentations and portfolio building.',
                        ],
                    },
                    {
                        title: 'Soft Skills Development',
                        details: [
                            'Interview Preparation: Mock interviews, problem-solving exercises, and feedback.',
                            'CV Optimization: Professional CV generation tailored for data analyst roles.',
                            'LinkedIn Optimization: Crafting a profile that attracts recruiters.',
                            'Communication Skills: Effective storytelling with data.',
                        ],
                    },
                ],
        careers:[
            { title: 'High-Demand Roles You Can Secure:',
           details:[
            'Data Analyst',
                    'Business Intelligence Analyst',
                    'Data Visualization Specialist',
                    'SQL Developer',
                    'Junior Data Scientist',
              
        ],
    },
    {title: 'Earning Potential and Global Opportunities:',
        details: [ 'Potential employers include global tech firms, financial institutions, consulting firms, and startups, with starting salaries ranging from €45,000 to €65,000 annually (with visa sponsorship opportunities).',
          ],
       },
             ],
        
        instructor: {
            name: 'Philip',
            photo: tutor, 
            bio: 'Philip is an accomplished data science tutor with 8+ years of professional experience in analytics and technology education. He specializes in transforming complex data concepts into practical learning experiences. His expertise spans advanced Python programming, machine learning algorithms, statistical modeling, and data visualization techniques etc.With a proven track record of training 500+ students in advanced data science skills, Philip combines academic rigor with industry-relevant insights. He is passionate about making data science education accessible and engaging, skillfully translating theoretical concepts into real-world problem-solving strategies. His comprehensive approach includes personalized learning strategies, hands-on practical training, and interactive instruction methods.Philip’s core competencies include advanced Python programming, machine learning algorithms, statistical analysis, data visualization techniques, predictive modeling, and AI fundamentals. His teaching philosophy emphasizes practical application, ensuring students not only understand theoretical concepts but can also implement them effectively in professional environments.As an educator and researcher, Philip continues to develop innovative curricula, contribute to computational research, and inspire the next generation of data science professionals.',
            stats: {
              studentsTaught: '50+',
              experience: '8+',
              courses: '6+',
              review: '5',
            },
          },
          testimonials: [
            {
                name: 'Yinka F',
                initial: 'YF',
                rating: 5,
                text: 'From resume writing to interview prep, the career support was amazing." – .',
        
            },
            {
                name: 'Blessing O',
                initial: 'BO',
                rating: 4,
                text: 'I went from a beginner to a certified professional within months.',
         
              }
        ],
    },
    {
        id: 7,
        name: 'Product Management',
        price: '€370 - €750',
        image: Courses7,
        students: '35 enrolled',
        description: 'Be Equipped With Skills Required To Excel As Product Managers or Product Owners in Just 10-14 Weeks.',
        tutor: 'Michael',
        lastUpdated: '1/2025',
        aboutThisCourse: 'Kayrex Academy equips aspiring and seasoned professionals with the skills required to excel as Product Managers or Product Owners 10-14 weeks. Our global reach extends to learners from Ireland, Canada, the UK, the US, Australia, and beyond. With an emphasis on practical tools, real-world projects, and industry-standard practices, our Product Management program ensures job readiness and career advancement.',
        programFeatures: [
            {
                title: 'What You Gain:',
                details: [
                    'Earn globally recognized certifications, with starting salaries from €65,000 annually.',
                    'Visa sponsorship job opportunities.',
                    'Hands-on training with industry-standard tools and real-world scenarios.',
                    'Lifetime access to Kayrex resources and mentorship.',
                    'Flexible payment plans and scholarships available.',
                ],
            },
            {
                title: 'Key Benefits for Kayrex Students:',
                details: [
                    'Certifications: Globally recognized certifications to validate your expertise.',
                    'Hands-On Projects: Real-world scenarios and live project implementations.',
                    'Career Support: Job referrals, referencing, and post-employment support.',
                    'Flexible Payment: Installment plans and scholarships to make learning accessible.',
                    'Lifetime Access: Continuous learning and mentorship resources.',
                ],
            },
        ],
        curriculumOverview: [
            {
                title: 'Foundations of Product Management',
                details: [
                    'Introduction to Product Management: Understanding the roles of Product Managers and Product Owners.',
                    'Product Life Cycle: Conceptualization, development, launch, and retirement.',
                    'Agile Methodologies: Scrum, Kanban, and SAFe frameworks.',
                    'Key Tools: Introduction to Notion, Jira, and FigJam.',
                ],
            },
            {
                title: 'Market Research and Analysis',
                details: [
                    'Conducting user interviews and surveys.',
                    'Competitor analysis and benchmarking.',
                    'Identifying market gaps and opportunities.',
                    'Frameworks: SWOT, PESTLE, and Porter’s Five Forces.',
                ],
            },
            {
                title: 'Product Vision and Strategy',
                details: [
                    'Crafting a compelling product vision.',
                    'Aligning product goals with business objectives.',
                    'Prioritization techniques: MoSCoW, RICE, and Weighted Scoring.',
                    'Roadmap creation and stakeholder alignment.',
                ],
            },
            {
                title: 'User Experience (UX) Design',
                details: [
                    'Principles of user-centered design.',
                    'Wireframing and prototyping using FigJam.',
                    'Usability testing and feedback integration.',
                    'Collaborating with design teams to create intuitive user interfaces.',
                ],
            },
            {
                title: 'Data-Driven Decision Making',
                details: [
                    'Setting and tracking key performance indicators (KPIs).',
                    'Understanding metrics: customer acquisition, retention, and lifetime value.',
                    'Using analytics tools for insights and reporting.',
                    'A/B testing and iterative improvements.',
                ],
            },
            {
                title: 'Product Development',
                details: [
                    'Collaborating with cross-functional teams (engineering, design, marketing).',
                    'Writing effective user stories and acceptance criteria.',
                    'Sprint planning and backlog grooming in Jira.',
                    'Managing product launches and post-launch analysis.',
                ],
            },
            {
                title: 'Advanced Topics in Product Management',
                details: [
                    'Product positioning and pricing strategies.',
                    'Managing enterprise products versus consumer products.',
                    'Handling crises and making tough trade-offs.',
                    'Global product management: Adapting to different markets and cultures.',
                ],
            },
            {
                title: 'Real-Life Project Implementation',
                details: [
                    'Work on live product management scenarios.',
                    'Develop product roadmaps and strategies for real-world use cases.',
                    'Case studies from industries like technology, healthcare, and fintech.',
                    'End-to-end product presentations for your portfolio.',
                ],
            },
            {
                title: 'Soft Skills Development',
                details: [
                    'Interview Preparation: Mock interviews and scenario-based problem-solving.',
                    'CV Optimization: Professional CV creation tailored for product management roles.',
                    'LinkedIn Optimization: Crafting a profile that attracts top recruiters.',
                    'Communication Skills: Effective storytelling and stakeholder management.',
                ],
            },
        ],
        careers: [
            {
                title: 'High-Demand Roles You Can Secure:',
                details: [
                    'Product Manager',
                    'Product Owner',
                    'Business Analyst',
                    'UX Researcher',
                    'Agile Coach',
                ],
            },
            {
                title: 'Earning Potential and Global Opportunities:',
                details: [
                    'Potential employers include global tech firms, startups, consulting companies, and financial institutions, with starting salaries ranging from €65,000 to €90,000 annually (with visa sponsorship opportunities).',
                ],
            },
        ],
        instructor: {
            name: 'Michael',
            photo: tutor,
            bio: 'Michael is a Software Product Manager with 7+ years experience and a solid foundation in Computer Science. Michael`s experience is not limited to just managing teams of developers and designers, but also helping them in ideating, product discovery, developing , launching, and maintenance of apps from scratch with a strong entrepreneur DNA. The achievement that he is most proud of is growing and scaling Pledre from 300+ users to 3000+ in 6 months by adding a feature based on user feedback. Michael is currently working on scaling alumunite.co from -1 to 0.',
            stats: {
                studentsTaught: '80+',
                experience: '7+',
                courses: '6+',
                review: '5',
            },
        },
        testimonials: [
            {
                name: 'Bukola T',
                initial: 'BT',
                rating: 5,
                text: 'Kayrex Academy turned my passion into a profession. I’m forever grateful!',
                image: student2,
            },
            {
                name: 'Chidinma I',
                initial: 'CI',
                rating: 4,
                text: 'The job placement support is phenomenal. I got multiple offers after graduating.',
                image: student2,
            },
        ],
    },
    
];


