import React from "react";


const Instructor = ({ selectedCourse }) => {
  if (!selectedCourse || !selectedCourse.instructor) {
      return <p>Instructor details are unavailable. Please select a valid course.</p>;
  }

  const { instructor } = selectedCourse;

  return (
      <div className="instructor-container">
          <div className="instructor-header">
              <div className="icon-circle">
                  <i className="fas fa-globe"></i>
              </div>
              <p className="instructor-label">Your Instructor</p>
          </div>
          <h1 className="instructor-heading">Meet {instructor.name}</h1>

          <div className="instructor-content">
              <img
                  src={instructor.photo}
                  alt={instructor.name}
                  className="instructor-photo"
              />
              <p className="instructor-info">{instructor.bio}</p>
          </div>

          <div className="stats-container">
              <div className="stat-box">
                  <h2>{instructor.stats.studentsTaught}</h2>
                  <p>Students taught</p>
              </div>
              <div className="stat-box">
                  <h2>{instructor.stats.review}</h2>
                  <p>Reviews </p>
              </div>
              <div className="stat-box">
                  <h2>{instructor.stats.experience}</h2>
                  <p>Years of experience</p>
              </div>
              <div className="stat-box">
                  <h2>{instructor.stats.courses}</h2>
                  <p>Lessons</p>
              </div>
          </div>
      </div>
  );
};


export default Instructor;
