import React from 'react';

const CourseBanner = ({ selectedCourse }) => {
  return (
    <div className="course-banner">
      <div className="course-header">
        <div className="course-info">
          <p className="breadcrumbs">
            <span>Courses</span> ›  › ›<span>{selectedCourse.name}</span>
          </p>
          <h1>{selectedCourse.name}</h1>
          <p className="course-description">{selectedCourse.description}</p>
          <div className="ratings">
            <span className="rating-value">4.6</span>
            <span className="stars">★★★★★</span>
            <span className="ratings-count">(156 ratings)</span>
            <span className="students">{selectedCourse.students}</span>
          </div>
          <p className="meta-info">
            Tutored by <span className="creator">{selectedCourse.tutor}</span>
          </p>
          <p className="last-updated">
            <span>Last updated {selectedCourse.lastUpdated}</span>      
          </p>
        </div>
        <div className="course-preview">
            <img className="course-preview-img" src={selectedCourse.image} alt="Course Preview" />
        </div>
      </div>
    </div>
  );
};

export default CourseBanner;
