import React from "react";

const CourseDescription = ({ selectedCourse }) => {


  return (
    <div className="coursereview-container">
      <h3>About this course</h3>
        <p >
          {selectedCourse.aboutThisCourse}
        </p>
        <div className="program-features">
          <h5>Program Features</h5>
        {selectedCourse.programFeatures.map((feature, index) => (
          <div key={index} className="feature-item">
            <ul>
            <li><h6>{feature.title}</h6></li>
            {/* if details is an array or a string */}
            {Array.isArray(feature.details) ? (
              <ul>
                {feature.details.map((detail, i) => (
                  <li key={i}>{detail}</li>
                ))}
              </ul>
            ) : (
              <ul>
              <li>{feature.details}</li>
              </ul>
            )}
            </ul>
          </div>
        ))}
      </div>
   
    </div>
  );
};

export default CourseDescription;
