import React from 'react'
import Header from '../components/header';
import VisionMission from "../components/visionmission";
import Footer from '../components/footer';
import MoreInfo from '../components/moreinfo';
import AboutUsDetails from '../components/aboutusdetails';

const AboutPage=()=> {
  return (
    <div>
        <Header></Header>
        <AboutUsDetails></AboutUsDetails>
        <VisionMission></VisionMission>
        <MoreInfo></MoreInfo>
        
        <Footer></Footer>
    </div>
  )
}

export default AboutPage;