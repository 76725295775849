import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import student2 from "../assets/avatar2.jpeg";

const CourseReview = ({ selectedCourse }) => {
  const [showFull, setShowFull] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [testimonials, setTestimonials] = useState(selectedCourse?.testimonials || []); // Local state for testimonials
  const [newReview, setNewReview] = useState({
    name: "",
    profession: "",
    testimonial: "",
    rating: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReview((prev) => ({ ...prev, [name]: value }));
  };

  const handleRating = (rate) => {
    setNewReview((prev) => ({ ...prev, rating: rate }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Add the new review to the local state
    const updatedTestimonials = [
      ...testimonials,
      { ...newReview, id: testimonials.length + 1, image: student2 },
    ];
    setTestimonials(updatedTestimonials);

    // Clear the form and close the modal
    setNewReview({
      name: "",
      profession: "",
      testimonial: "",
      rating: 0,
    });
    setModalOpen(false);
  };

  if (!selectedCourse) {
    return <p>Please select a course to see what you will learn.</p>;
  }

  // Determine how many testimonials to show based on the state of `showFull`
  const visibleTestimonials = showFull ? testimonials : testimonials.slice(0, 2);

  return (
    <div className="coursereview-container">
      <h3>What Our Students Are Saying</h3>
      <h5>
        Excellent <span className="rating">"★★★★★"</span> 4.7 out of 5 based on
        186 reviews
      </h5>
      <h6>
        Don’t just take my word for it—hear from students who have transformed
        their skills and careers with this course.
      </h6>
      {visibleTestimonials.map((testimonial, index) => (
        <div className="coursereview-card" key={index}>
          <div className="user-info">
          <div className="avatar">{testimonial.initial}</div>
            <div className="name">{testimonial.name}</div>
          </div>
          <div className="rating">
            {"★".repeat(testimonial.rating)}
          </div>
          <p className="text">{testimonial.text}</p>
        </div>
      ))}
      <div className="review-flex">
        {testimonials.length > 2 && (
          <button className="show-more" onClick={() => setShowFull(!showFull)}>
            {showFull ? "Show Less" : "Show More"}
          </button>
        )}
        <button className="review-button red" onClick={() => setModalOpen(true)}>
          Add Review
        </button>
      </div>
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Add Your Review</h3>
            <button
              type="button"
              className="close-btn"
              onClick={() => setModalOpen(false)}
            >
              X
            </button>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name..."
                  value={newReview.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="profession">Course</label>
                <input
                  type="text"
                  id="profession"
                  name="profession"
                  placeholder="Your Profession..."
                  value={newReview.course}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="testimonial">Testimonial</label>
                <textarea
                  id="testimonial"
                  name="testimonial"
                  placeholder="Your Testimonial..."
                  value={newReview.testimonial}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
              <div className="form-group">
                <label>Rating</label>
                <div className="rating-stars">
                  {[...Array(5)].map((_, index) => (
                    <FontAwesomeIcon
                      key={index}
                      icon={faStar}
                      className={`star ${
                        newReview.rating > index ? "active" : ""
                      }`}
                      onClick={() => handleRating(index + 1)}
                    />
                  ))}
                </div>
              </div>
              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CourseReview;
