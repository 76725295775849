import React, { useState, useRef } from "react";

const WhatYouLearn = ({ selectedCourse }) => {
  const [visibleCount, setVisibleCount] = useState(4);
  const containerRef = useRef(null);

  if (!selectedCourse) {
    return <p>Please select a course to see what you will learn.</p>;
  }

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 4);
  };

  const handleShowLess = () => {
    setVisibleCount(5);
    window.scrollTo({
      top: containerRef.current.offsetTop,
      behavior: "smooth",
    });
  };
  

  return (
    <div className="what-you-learn" ref={containerRef}>
      <h2>Curriculum Overview</h2>
      <div className="learn-grid">
        {selectedCourse.curriculumOverview.slice(0, visibleCount).map((item, index) => (
          <div  key={index}>
            <h6 className="learn-item">{item.title}</h6>
            <ul>
              {item.details.map((detail, i) => (
                <li key={i}>{detail}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <div className="learn-actions">
        {visibleCount < selectedCourse.curriculumOverview.length ? (
          <button className="show-more" onClick={handleShowMore}>
            Show More
          </button>
        ) : (
          <button className="show-more" onClick={handleShowLess}>
            Show Less
          </button>
        )}
      </div>
    </div>
  );
};

export default WhatYouLearn;
