import React from 'react';

const MoreInfo = () => {
  const info = [
    {
      details: [
        'Industry-specific hands-on training through projects, labs, and virtual classes (3–6 months duration).',
        '24/7 access to approachable tutors and support systems.',
        'FREE professional CV creation, interview aid, and referencing services.',
        'Certifications and recommendations that enhance career prospects, including eligibility for visa sponsorship job opportunities.',
        'Lifetime access to learning resources, ensuring continuous skill development.',
        'Affordable and flexible course fees designed to suit various budgets.',
      ],
    },
  ];

  const coreValues = [
    {
      title: 'Empowerment',
      details: [
        'We believe in empowering our students and clients with the skills, tools, and confidence to achieve their goals.',
      ],
    },
    {
      title: 'Excellence',
      details: [
        'Our commitment to excellence drives us to deliver world-class training, certifications, and consulting services tailored to industry demands.',
      ],
    },
    {
      title: 'Accessibility',
      details: [
        'We strive to make tech education and career opportunities accessible to everyone, regardless of their experience, location, or financial background.',
      ],
    },
    {
      title: 'Innovation',
      details: [
        'We are dedicated to staying at the forefront of technological advancements, equipping our students with the most current and practical knowledge.',
      ],
    },
  ];

  return (
    <div className="vision-mission" >
    <div className='simple-flex'>
    <div className="identifier"></div>
    <h4 className='bolder'>WHAT SETS US APART</h4>
    </div>
      <div className="learn-grid what-you-learn">
        {info.map((item, index) => (
          <div key={index}>
            <ul>
              {item.details.map((detail, i) => (
                <li key={i}>{detail}</li>
              ))}
            </ul>
          </div>
        ))}
    
      </div>
      <div className='simple-flex'>
    <div className="identifier"></div>
    <h4 className='bolder'>OUR CORE VALUES</h4>
    </div>
      <div className="learn-grid what-you-learn">
        {coreValues.map((value, index) => (
          <div key={index}>
            <h6 className="learn-item margin-right">{value.title}</h6>
            <ul>
              {value.details.map((detail, i) => (
                <li key={i}>{detail}</li>
              ))}
            </ul>
          </div>
          
        ))}
      </div>

    </div>
  );
};

export default MoreInfo;
