import React, { useEffect } from "react";
import Header from "../components/header";
import Toplevel from "../components/toplevel";
import Services from "../components/services";
import Contactus from "../components/contactus";
import Footer from "../components/footer";
import { NewVisitor } from "../services/server";
import FaqAccordion from "../components/faq";
import Testimonials from "../components/testimonials";
import Courses from "../components/courses";
// import BlogOverview from "../components/blogoverview";
import AboutUs from "../components/aboutus";
import Benefit from "../components/benefit";
import SuccessStories from "../components/successstories";

const Home = () => {
    useEffect(()=>{
        const newVisitor=async()=>{
            await NewVisitor()
        }
        newVisitor()
    },[])
  return (
    <div>
      <Header></Header>
      <Toplevel></Toplevel>
      <AboutUs></AboutUs>
      <Benefit></Benefit>
      <Services></Services>
      <Courses></Courses>
      <Testimonials></Testimonials>
      <SuccessStories></SuccessStories>
      <FaqAccordion></FaqAccordion>
      <Contactus></Contactus>
      {/* <BlogOverview></BlogOverview> */}
      <Footer></Footer>
    </div>
  );
};

export default Home;
