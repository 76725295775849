import React from "react";


const visions = [
  {
    name: "Vision",
    description: "To bridge the global tech skills gap by providing accessible, industry-relevant training and mentorship to individuals from all backgrounds, enabling them to unlock their full potential and excel in their chosen IT careers.",
    icon: "fa-dollar-sign", 
    color: "#8a4a4e", 
    
  },
  {
    name: "Mission",
    description: "To become a globally recognized leader in tech education and consulting, fostering innovation and inclusion in the tech industry while empowering thousands of individuals to achieve their dreams of a fulfilling career in technology. ",
    icon: "fa-cogs", 
    color: "#f1dcde", 
  },

];

const VisionMission = () => {
  return (
    <div className="vision-mission">
    <div className="simple-flex">
    <div className="identifier"></div>
    <h4 className="bolder">VISION AND MISSION</h4>
  </div>
    <div className="vision-container">
     
      {visions.map((vision, index) => (
        <div className="vision" key={index}>
          <div className="circle" style={{ borderColor: vision.color }}>
            <div className="circle-inner" style={{ backgroundColor: vision.color }}>
              <span className="vision-number">{vision.name}</span>
            </div>
          </div>
          <div className="line" style={{ backgroundColor: vision.color }}></div>
          <div className="content">
            <h4>{vision.title}</h4>
            <p>{vision.description}</p>
            <i className={`fas ${vision.icon}`} style={{ color: vision.color }}></i>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default VisionMission;
