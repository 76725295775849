import React from 'react'

const PracticalTools=({selectedCourse}) =>{
    if (!selectedCourse.practicalTools || selectedCourse.practicalTools.length === 0) {
        return null; // Do not render the component
      }
  return (
   
        <div className="what-you-learn" >
      <h2>Practical Tools and Technique</h2>
      <div className="learn-grid">
        {selectedCourse.practicalTools.map((practicalTool, index) => (
          <div className="learn-item" key={index}>
            {practicalTool}
          </div>
        ))}
      </div>
      </div>
    

  );
};

export default PracticalTools;