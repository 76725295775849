import React from "react";
import Header from "../components/header";
import Contactus from "../components/contactus";
import Footer from "../components/footer";
import { useLocation } from 'react-router';
import BlogBanner from "../components/blogbanner";
import BlogDescription from "../components/blogdescription";


const BlogPage = () => {
  const location = useLocation();
  const selectedBlog = location.state?.selectedBlog;


  
  return (
    <div>
      <Header></Header>
      {selectedBlog ? (
        <BlogBanner selectedBlog={selectedBlog} />
      ) : (
        <p>Please select a blog from the home page.</p>
      )}
      <BlogDescription selectedBlog={selectedBlog}></BlogDescription>

      <Contactus></Contactus>
      <Footer></Footer>
    </div>
  );
};

export default BlogPage;
