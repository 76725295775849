import React, { useState } from 'react';
import student2 from '../assets/avatar2.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import Img1 from '../assets/stories (1).jpeg';
import Img2 from '../assets/stories (2).jpeg';
import Img3 from '../assets/stories (3).jpeg';
import Img4 from '../assets/stories (4).jpeg';
import Img5 from '../assets/stories (5).jpeg';
import Img6 from '../assets/stories (6).jpeg';
import Img7 from '../assets/stories (7).jpeg';
import Img8 from '../assets/stories (8).jpeg';
import Img9 from '../assets/stories (9).jpeg';
import Img10 from '../assets/stories (10).jpeg';
import Img11 from '../assets/stories (11).jpeg';
import Img12 from '../assets/stories (12).jpeg';
import Img13 from '../assets/stories (13).jpeg';
import Img14 from '../assets/stories (14).jpeg';
import Img15 from '../assets/stories (15).jpeg';
import Img16 from '../assets/stories (16).jpeg';
import Img17 from '../assets/stories (17).jpeg';
import Img18 from '../assets/stories (18).jpeg';
import Img19 from '../assets/stories (19).jpeg';
import Img20 from '../assets/stories (20).jpeg';
import Img21 from '../assets/stories (21).jpeg';
import Img22 from '../assets/stories (22).jpeg';
import Img23 from '../assets/stories (23).jpeg';

const stories = [
  { id: 1, image: Img1 },
  { id: 2, image: Img2 },
  { id: 3, image: Img3 },
  { id: 4, image: Img4 },
  { id: 5, image: Img5 },
  { id: 6, image: Img6 },
  { id: 7, image: Img7 },
  { id: 8, image: Img8 },
  { id: 9, image: Img9 },
  { id: 10, image: Img10 },
  { id: 11, image: Img11 },
  { id: 12, image: Img12 },
  { id: 13, image: Img13 },
  { id: 14, image: Img14 },
  { id: 15, image: Img15 },
  { id: 16, image: Img16 },
  { id: 17, image: Img17 },
  { id: 18, image: Img18 },
  { id: 19, image: Img19 },
  { id: 20, image: Img20 },
  { id: 21, image: Img21 },
  { id: 22, image: Img22 },
  { id: 23, image: Img23 },
];


const SuccessStories = () => {

  return (
    <div className="featured-main">
      <div className="simple-flex">
            <div className="identifier"></div>
            <h4 className="bolder">OUR SUCCESS STORIES</h4>
      </div>
      <div className="simple-flex-courses">
        {stories.map((stories) => (
          <div className="courses-box student-review-card" key={stories.id}>
            <img className='success-image' src={stories.image}></img>
    
          </div>
        ))}
      </div>

    </div>
  );
};

export default SuccessStories;
