import React from 'react'

const CareerPath=({selectedCourse})=>{
    
    if (!selectedCourse.careers || selectedCourse.careers.length === 0) {
        return null; // Do not render the component
      }
  return (
    <div className="what-you-learn" >
      <h2>Career Pathway</h2>
      <div className="learn-grid">
        {selectedCourse.careers.map((career, index) => (
          <div key={index}>       
                <h6 className="learn-item" >{career.title}</h6>  
                <ul>
                  {career.details.map((detail, i) => (
                    <li key={i}>{detail}</li>
                  ))}
                </ul>
          </div>
        ))}
      </div>
      </div>
  )
}

export default CareerPath;