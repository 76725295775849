import React, { useState } from 'react';
import student2 from '../assets/avatar2.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const reviews = [
  {
    id: 1,
    name: 'Olumide T.',
    course: 'Scrum Master',
    testimonial: 'Kayrex Academy`s hands-on approach made learning practical and fun. I felt job-ready from day one!',
    rating: 5,
  },
  {
    id: 2,
    name: 'Ifeanyi E.',
    course: 'Cybersecurity',
    testimonial: 'Kayrex gave me the tools to excel in my role as a cybersecurity analyst. Thank you for the knowledge!',
    rating: 4,
  },

  {
    id: 3,
    name: 'Adebola A.',
    course: 'Scrum Master',
    testimonial: 'The Scrum Master course gave me the confidence to lead agile teams effectively. I couldn’t have done it without Kayrex.',
    rating: 5,
  },
  {
    id: 4,
    name: 'Ngozi M.',
    course: 'Business Analysis',
    testimonial: 'I transitioned into tech seamlessly thanks to their comprehensive Business Analysis course.',
    rating: 4,
  },
  {
    id: 5,
    name: 'Chukwuemeka O.',
    course: '',
    testimonial: 'I landed a €70,000 job right after completing my training. The skills I gained here are invaluable!',
    rating: 5,
  },

  {
    id: 6,
    name: 'Hauwa B.',
    course: '',
    testimonial: 'The mentorship program was a turning point in my career. My mentor truly cared about my success.',
    rating: 5,
  },
  {
    id: 7,
    name: 'Tunde I.',
    course: '',
    testimonial: 'The real-world projects helped me build a portfolio that impressed my employers.',
    rating: 4,
  },
  {
    id: 8,
    name: 'Chioma E.',
    course: 'Project Management',
    testimonial: 'The Project Management courses on Kayrex are top-notch. The content is well-structured, and the tools provided have made me more effective in managing teams and delivering projects on time. I couldn’t be happier!',
    rating: 4,
  },
  {
    id: 9,
    name: 'Funmi A.',
    course: 'Project Management',
    testimonial: 'Their project management training was detailed and well-structured. Highly recommend!',
    rating: 5,
  },
  {
    id: 10,
    name: 'Yinka F.',
    course: '',
    testimonial: 'From resume writing to interview prep, the career support was amazing.',
    rating: 5,
  },
  {
    id: 11,
    name: 'Amaka N.',
    course: '',
    testimonial: 'I’ve recommended Kayrex Academy to all my friends—it’s the best investment I’ve made in my career.',
    rating: 5,
  },
  {
    id: 12,
    name: 'Kolawole A.',
    course: '',
    testimonial: 'The instructors are highly knowledgeable and always willing to help.',
    rating: 5,
  },
  {
    id: 13,
    name: 'Blessing O.',
    course: '',
    testimonial: 'I went from a beginner to a certified professional within months.',
    rating: 5,
  },
  {
    id: 14,
    name: 'Gbenga L.',
    course: '',
    testimonial: 'The flexible payment plans made it easy for me to afford high-quality education.',
    rating: 4,
  },
  {
    id: 15,
    name: 'Folake O.',
    course: '',
    testimonial: 'I now work in one of the top tech firms in Europe, thanks to Kayrex Academy!',
    rating: 4,
  },
  {
    id: 16,
    name: 'Nnamdi K.',
    course: '',
    testimonial: 'The curriculum is up-to-date and aligns with current industry standards.',
    rating: 4,
  },
  {
    id: 17,
    name: 'Mary J.',
    course: '',
    testimonial: 'My confidence skyrocketed after completing the Agile Methods training.',
    rating: 5,
  },
  {
    id: 18,
    name: 'Tolu O.',
    course: '',
    testimonial: 'I loved the interactive workshops—they made learning enjoyable.',
    rating: 5,
  },
  {
    id: 19,
    name: 'Chidinma I.',
    course: '',
    testimonial: 'The job placement support is phenomenal. I got multiple offers after graduating.',
    rating: 5,
  },
  {
    id: 20,
    name: 'Ibrahim S.',
    course: '',
    testimonial: 'Their focus on practical skills is what sets Kayrex apart from other academies.',
    rating: 5,
  },
  {
    id: 21,
    name: 'Zainab K.',
    course: ' Business Analysis',
    testimonial: 'I now lead a team of analysts, thanks to the skills I learned at Kayrex Academy.',
    rating: 4,
  },
  {
    id: 22,
    name: 'Kelechi M.',
    course: '',
    testimonial: 'The certifications I earned here opened doors to new opportunities.',
    rating: 5,
  },
  {
    id: 23,
    name: 'Segun A.',
    course: '',
    testimonial: 'The instructors made complex topics simple and easy to understand.',
    rating: 5,
  },
  {
    id: 24,
    name: 'Uche E.',
    course: '',
    testimonial: 'I appreciate the lifetime access to resources—I still refer to them in my job.',
    rating: 4,
  },
  {
    id: 25,
    name: 'Bukola T.',
    course: '',
    testimonial: 'Kayrex Academy turned my passion into a profession. I’m forever grateful!',
    rating: 5,
  },
];


const Testimonials = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [newReview, setNewReview] = useState({
    name: '',
    course: '',
    testimonial: '',
    rating: 0,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewReview((prev) => ({ ...prev, [name]: value }));
  };

  const handleRating = (rate) => {
    setNewReview((prev) => ({ ...prev, rating: rate }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form submitted successfully!");
    reviews.push({ ...newReview, id: reviews.length + 1, image: student2 });
  
    // Refresh the form
  setNewReview({
    name: '',
    course: '',
    testimonial: '',
    rating: 0,
  });
  setModalOpen(false);
};
  return (
    <div className="featured-main">
      <div className="simple-flex">
        <div className="review-flex">
          <div className="left-content">
            <div className="identifier"></div>
            <h4 className="bolder">OUR STUDENTS REVIEWS</h4>
          </div>
          <button className="review-button" onClick={() => setModalOpen(true)}>
            Add Review
          </button>
        </div>
      </div>
      <div className="simple-flex-courses">
        {reviews.map((review) => (
          <div className="courses-box student-review-card" key={review.id}>
            <h5 className="course-header bolder">{review.name}</h5>
            <p className="course-content sc">{review.course}</p>
            <p className="course-content sc testimonial-padding">{review.testimonial}</p>
            <div className="stars">
              {[...Array(5)].map((_, index) => (
                <FontAwesomeIcon
                  key={index}
                  icon={faStar}
                  style={{ color: index < review.rating ? '#FFD700' : '#ccc' }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Add Your Review</h3>
            <button
                type="button"
                className="close-btn"
                onClick={() => setModalOpen(false)}
              >
                X
              </button>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name..."
                  value={newReview.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group"> 
  <label htmlFor="course">Course</label>
  <select
    id="course"
    name="course"
    value={newReview.course}
    onChange={handleInputChange}
    required
  >
    <option value="" disabled>
      Select a course...
    </option>
    <option value="Data Analysis">Data Analysis</option>
    <option value="Business Analysis">Business Analysis</option>
    <option value="Cybersecurity">Cybersecurity</option>
    <option value="Project Management">Project Management</option>
    <option value="Scrum Mastery">Scrum Master</option>
    <option value="Software Development">Software Development</option>
    <option value="UI/UX Design">Product Management</option>
  </select>
</div>

              <div className="form-group">
                <label htmlFor="testimonial">Testimonial</label>
                <textarea
                  id="testimonial"
                  name="testimonial"
                  placeholder="Your Testimonial..."
                  value={newReview.testimonial}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
              <div className="form-group">
                <label>Rating</label>
                <div className="rating-stars">
                  {[...Array(5)].map((_, index) => (
                    <FontAwesomeIcon
                      key={index}
                      icon={faStar}
                      className={`star ${newReview.rating > index ? 'active' : ''}`}
                      onClick={() => handleRating(index + 1)}
                    />
                  ))}
                </div>
              </div>
              <button type="submit" className="submit-btn">
                Submit
              </button>
            
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Testimonials;
