import React, {useEffect} from "react";
import Header from "../components/header";
import Contactus from "../components/contactus";
import Footer from "../components/footer";
import CourseBanner from "../components/coursebanner";
import { useLocation } from 'react-router';
import WhatYouLearn from "../components/whatyoulearn";
import CourseReview from "../components/coursereview";
import CourseDescription from "../components/coursedescription";
import Instructor from "../components/instructor";
import PracticalTools from "../components/practicaltools";
import CareerPath from "../components/careerpath";


const CoursesPage = () => {
  const location = useLocation();
  const selectedCourse = location.state?.selectedCourse;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <Header></Header>
      {selectedCourse ? (
        <CourseBanner selectedCourse={selectedCourse} />
      ) : (
        <p>Please select a course from the home page.</p>
      )}
      <CourseDescription  selectedCourse={selectedCourse}></CourseDescription>
      <WhatYouLearn  selectedCourse={selectedCourse}></WhatYouLearn>
      <PracticalTools selectedCourse={selectedCourse}></PracticalTools>
      <CareerPath selectedCourse={selectedCourse}></CareerPath>
      <Instructor  selectedCourse={selectedCourse}></Instructor>

      <CourseReview  selectedCourse={selectedCourse}></CourseReview>
     


      <Contactus></Contactus>
      <Footer></Footer>
    </div>
  );
};

export default CoursesPage;
