import React, { useState } from "react";
import ContactUs from "../assets/contactus.svg";
import { NewFormRegistration } from "../services/server";

const Contactus = () => {
  const [fullname,fullnameChanged]=useState()
  const [email,emailChanged]=useState()
  const [phone,phoneChanged]=useState()
  const [interest,interestChanged]=useState()
  const [note,noteChanged]=useState()
  const RegisterForm=async ()=>{
    await NewFormRegistration({
      fullname:fullname,
      email:email,
      phone:phone,
      interest:interest,
      note:note
    })
    fullnameChanged("")
    emailChanged("")
    phoneChanged("")
    interestChanged("")
    noteChanged("")
    alert("We have noted your interest, a Kayrex personnel willo contact you shortly")
    document.getElementById("root").scrollIntoView({ behavior: "smooth" });
  }
  return (
    <div className="contactusmain" id="contactus">
      <div className="transition">
        <img
          alt='<a href="https://www.freepik.com/free-vector/flat-illustration-customer-support_12893086.htm#query=customer%20service&position=7&from_view=keyword&track=ais_user&uuid=76ba5f64-a52d-45af-a1f0-696b4981f844">Image by freepik</a>'
          src={ContactUs}
          className="contactusimg"
        />
        <p className="ready">Ready to Transition into Tech?</p>
        <p className="expert">Get Expert Advice</p>
      </div>
      <div className="contactus-form">
        <h5>Complete Form Details</h5>
        <div>
          <h6>Fullname <span style={{color:'lightgray'}}>*</span></h6>
          <input placeholder="What can we call you?" value={fullname} onChange={(chg)=>fullnameChanged(chg.target.value)} />
        </div>
        <div>
          <h6>Email <span style={{color:'lightgray'}}>*</span></h6>
          <input placeholder="Enter active email"  value={email} onChange={(chg)=>emailChanged(chg.target.value)}/>
        </div>
        <div>
          <h6>Phone <span style={{color:'lightgray'}}>*</span></h6>
          <input placeholder="Enter phone number"  value={phone} onChange={(chg)=>phoneChanged(chg.target.value)}/>
        </div>
        <div>
          <h6>Your Interest <span style={{color:'lightgray'}}>*</span></h6>
          <input placeholder="What do you want to learn" list="interest"  value={interest} onChange={(chg)=>interestChanged(chg.target.value)}/>
          <datalist id="interest">
            <option>Product Management</option>
            <option>Project Management</option>
            <option>Business Analysis</option>
            <option>Data Analysis</option>
            <option>Cyber Security</option>
            <option>Software Development</option>
            <option>Cloud Computing</option>
            <option>DevOps Engineering</option>
            <option>UI/UX Designer</option>
          </datalist>
        </div>
        <div>
          <h6>Short Note</h6>
          <textarea placeholder="Any info that will help support you better?"  style={{width:'100%',height:'150px'}} value={note} onChange={(chg) => noteChanged(chg.target.value)}/>
        </div>
        <button className="submitbut" onClick={()=>RegisterForm()}>Submit</button>
      </div>
    </div>
  );
};

export default Contactus;
