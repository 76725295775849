import React from 'react';

const BlogBanner = ({ selectedBlog }) => {
  return (
    <div className="course-banner">
      <div className="course-header">
        <div className="course-info">
          <p className="breadcrumbs">
            <span>Blog Update</span> ›  › ›<span>{selectedBlog.date}</span>
          </p>
          <h1>{selectedBlog.title}</h1>
          <p className=" blog-banner-description">{selectedBlog.description}</p>
          <p className="meta-info">
            Written by <span className="creator">{selectedBlog.tutor}</span>
          </p>
          <p className="last-updated">
            <span>Last updated {selectedBlog.date}</span> 
          </p>
        </div>
        <div className="course-preview">
          <div className="video-preview">
            <img src={selectedBlog.image} alt="Course Image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogBanner;
