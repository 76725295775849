import React from "react";
import TopLevel from "../assets/banner2.png";
import { RxDragHandleDots1 } from "react-icons/rx";
import { BiLoaderCircle } from "react-icons/bi";
import { BsPeopleFill } from "react-icons/bs";

const Toplevel = () => {
  return (
    <div className="top-level-main">
      <div className="top-level">
        <BiLoaderCircle
          className="toplevel-designs toplevel-designs-small"
          style={{ top: "80px", left: "83%" }}
        />
        <RxDragHandleDots1
          className="mobile-invisible toplevel-designs"
          style={{ top: "100px", left: "40%" }}
        />
        <RxDragHandleDots1
          className="mobile-invisible toplevel-designs"
          style={{ top: "320px", left: "10px" }}
        />
        <div className="advert">
          <marquee behavior="scroll" direction="left">
            Registration is Ongoing! Book now to reserve a seat. Hurry up and
            secure your spot at Kayrex Academy today! Learn technical and
            non-technical skills to boost your career.
          </marquee>
        </div>
        <div className="toplevel-content">
          <h2>
           
            Empowering Tomorrow’s Tech<br></br> Leaders, One Step at a Time
          </h2>
          <p>
          Kayrex Academy is your trusted partner for transitioning into tech.<br></br> With industry-aligned programs,  expert instructors, hands-on training, <br></br>and dedicated mentorship, we’re here to help you unlock your full potential<br></br> and thrive in the world of technology with little or no experience.
          </p>
          <div className="simple-flex">
            <a
              href="#courses"
              className="toplevel-button"
            >
              View Courses
            </a>
            <a
              href="https://chat.whatsapp.com/B8UX1mBIZ3D5csGKUcOCdk"
              target="_blank"
              rel="noreferrer"
              className="toplevel-button-alt"
            >
              <div>
                <span>WhatsApp Community</span>&nbsp;
                <BsPeopleFill></BsPeopleFill>
              </div>
            </a>
          </div>
        </div>
        {/* <img alt="Kayrex Academy" src={TopLevel} className="top-level-image" /> */}
        <div class="image-container top-level-image" style={{ marginTop: "60px" }}>
          <img className=""  src={TopLevel} alt="MainImage" />
        </div>
      </div>
      <div>
        <svg
          height="100"
          width="100%"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          className="toplevel-triangle"
        >
          <path d="M0 0 L50 50 L100 0 Z" />
        </svg>
      </div>
    </div>
  );
};

export default Toplevel;
