import React from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.css";
import "./styles/custom.scss";
import "./styles/index.css";
import { BrowserRouter, Routes, Route } from "react-router";
import Home from "./pages/home";
import CoursesPage from "./pages/coursespage";
import BlogPage from "./pages/blogpage";
import AboutPage from "./pages/aboutpage";



const App = () => {

  return (
    <BrowserRouter>
      <Routes>
        {/* Home Page */}
        <Route
          path="/"
          element={<Home />}
        />

        {/* Courses Page */}
        <Route
          path="/courses"
          element={
            <CoursesPage/>
          }
        />
             {/* Courses Page */}
             <Route
          path="/aboutus"
          element={
            <AboutPage/>
          }
        />
              {/* Blog Page */}
              <Route
          path="/blogs"
          element={
            <BlogPage/>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
