import React from 'react'

const AboutUsDetails=()=> {
  return (
    <div id='about-container'>
         <div className="advert">
          <marquee behavior="scroll" direction="left">
            Registration is Ongoing! Book now to reserve a seat. Hurry up and
            secure your spot at Kayrex Academy today! Learn technical and
            non-technical skills to boost your career.
          </marquee>
        </div>
    <div className='simple-flex mt-5'>
    <div id="identifier"></div>
    <h4 className='bolder'>ABOUT US</h4>
    </div>
    <div  className='about-container'>
    <p>Kayrex Academy is a premier (Online) global technology training and consulting institution dedicated to empowering individuals with the skills and certifications needed to excel in the ever-evolving tech industry. Whether you're a seasoned professional looking to upskill or someone new to the tech field, we provide the resources, guidance, and opportunities to help you achieve your career goals.</p>

    <p>Having trained over 100+, we are glad to have operated across Ireland, Canada, the United Kingdom, the United States, Australia, and beyond, we specialize in delivering high-quality training in areas like Cyber Security, Data Analysis, Business Analysis, Software Engineering, Project Management, Scrum, and Product Management. With a focus on hands-on, practical learning, our programs ensure that every student gains the confidence and competence required to thrive in the tech world.
    </p>
    </div>

</div>
  )
}

export default AboutUsDetails;