import React, { useState, useRef } from 'react';
import Accordion from 'react-bootstrap/Accordion';

const FaqAccordion = () => {
    const [visibleCount, setVisibleCount] = useState(4);
    const containerRef = useRef(null);

    const handleShowMore = () => {
        setVisibleCount((prevCount) => prevCount + 4);
      };
    
      const handleShowLess = () => {
        setVisibleCount(5);
        window.scrollTo({
          top: containerRef.current.offsetTop,
          behavior: "smooth",
        });
      };

    const faqs = [
        {
            question: 'Do I need prior experience to enroll in a course?',
            answer: 'No prior experience is needed for most of our courses. Our programs start with foundational knowledge and progress to advanced topics.',
        },
        {
            question: 'Are the certifications globally recognized?',
            answer: 'Yes, our certifications are recognized worldwide and help students stand out in competitive job markets.',
        },
        {
            question: 'Can I study online?',
            answer: 'Yes, we offer flexible online training options to accommodate busy schedules and allow students to learn from anywhere.',
        },
        {
            question: 'Are there installment payment plans available?',
            answer: 'Yes, we offer flexible installment payment options to make our programs accessible to everyone.',
        },
        {
            question: 'What tools will I need for online learning?',
            answer: 'All you need is a computer (depending on course selected) with an internet connection. For some courses, you may need access to specific software, which we guide you to install.',
        },
        {
            question: 'What if I have questions during the course?',
            answer: 'You can reach out to your instructor directly or contact our support team for assistance.',
        },
        {
            question: 'How can I access course materials after enrollment?',
            answer: 'Once enrolled, you will gain access to your dashboard where all course materials are available.',
        },
        {
            question: 'What is Kayrex Academy?',
            answer: 'Kayrex Academy is a professional training institute specializing in providing practical, industry-relevant courses to help individuals enhance their skills, secure certifications, and boost their career opportunities in fields like Project Management, Business Analysis, Scrum, Software Engineering, Cybersecurity, and more.',
        },
        {
            question: 'Who are the courses for?',
            answer: 'Our courses are designed for beginners, professionals looking to upskill, and career changers. Whether you\'re new to the field or seeking to enhance your expertise, we have something for everyone.',
        },
        {
            question: 'How do I enroll in a course?',
            answer: 'You can enroll by visiting our website at www.kayrexacademy.com, selecting your desired course, and completing the registration process or contacting us via mediums provided on the website.',
        },
        {
            question: 'What happens after I register for a course?',
            answer: 'After registration, you will receive an email or message on WhatsApp with your course schedule, learning materials, and instructions on accessing our learning platform.',
        },
        {
            question: 'How long does each course take?',
            answer: 'Most courses last between 10–18 weeks, depending on the course content and intensity. Check the course details for specific timelines.',
        },
        {
            question: 'Are there real-world projects included?',
            answer: 'Yes, all our courses include practical projects, case studies, and hands-on assignments to help students apply their skills in real-world scenarios.',
        },
        {
            question: 'Will I get job placement assistance?',
            answer: 'Yes, we provide comprehensive career support, including CV optimization, LinkedIn profile development, mock interviews, and job referrals.',
        },
        {
            question: 'Do I need to complete assignments?',
            answer: 'Yes, assignments are essential for hands-on learning and will help you build a portfolio to showcase to potential employers.',
        },
        {
            question: 'Will I have access to the materials after the course?',
            answer: 'Yes, students get lifetime access to course materials and resources for continued learning.',
        },
        {
            question: 'Who are the instructors?',
            answer: 'Our instructors are experienced professionals with extensive industry knowledge. They are passionate about mentoring and guiding students.',
        },
        {
            question: 'How can I contact Kayrex Academy?',
            answer: 'You can contact us via:\n• WhatsApp: +353 89 989 2887\n• Email: info@kayrexacademy.com\n• Website: www.kayrexacademy.com',
        },
        {
            question: 'When do classes start?',
            answer: 'Most classes start on specific dates depending on if you intend to join the coming cohorts or take a private class. The next session begins 25th January—reserve your spot now!',
        },
    ];
    

    return (
        <div className='faq-section 'ref={containerRef}>
            <div className='container d-flex flex-column align-items-center faq-section-inner'>
                <h2 style={{color:'#000'}}>FREQUENTLY ASKED QUESTIONS</h2>
                <br></br>
                <Accordion defaultActiveKey="" flush className="custom-accordion">
                {faqs.slice(0, visibleCount).map((faq, index) => (
                        <Accordion.Item eventKey={index.toString()} key={index}>
                            <Accordion.Header>{faq.question}</Accordion.Header>
                            <Accordion.Body>{faq.answer}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
                <div className="learn-actions">
        {visibleCount < faqs.length ? (
          <button className="show-more" onClick={handleShowMore}>
            Show More
          </button>
        ) : (
          <button className="show-more" onClick={handleShowLess}>
            Show Less
          </button>
        )}
      </div>
            </div>
        </div>
    );
};

export default FaqAccordion;
