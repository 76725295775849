import React, { useState } from "react";

const BlogDescription = ({ selectedBlog }) => {
  const [showFull, setShowFull] = useState(false);

  // Function to split text into parts
  const splitIntoParts = (text, parts = 3) => {
    const sentences = text.split('. '); // Split by sentences
    const chunkSize = Math.ceil(sentences.length / parts); // Calculate chunk size
    const chunks = [];
    for (let i = 0; i < sentences.length; i += chunkSize) {
      chunks.push(sentences.slice(i, i + chunkSize).join('. ') + '.'); // Join sentences back with '.'
    }
    return chunks;
  };

  const paragraphs = splitIntoParts(selectedBlog.blogDetails);

  return (
    <div className="coursereview-container">
      <h3>More Information</h3>
      {paragraphs.map((paragraph, index) => (
        <p key={index} style={{ display: index === 0 || showFull ? "block" : "none" }}>
          {paragraph}
        </p>
      ))}
      <button className="show-more" onClick={() => setShowFull(!showFull)}>
        {showFull ? "Show Less" : "Show More"}
      </button>
    </div>
  );
};

export default BlogDescription;
