import React from 'react'

const AboutUs = () => {
  return (
    <div>
        <div className='simple-flex'>
        <div className="identifier"></div>
        <h4 className='bolder'>ABOUT US</h4>
        </div>
        <div  className='about-container'>
        <p>Kayrex Academy is a premier (Online) global technology training and consulting institution dedicated to empowering individuals with the skills and certifications needed to excel in the ever-evolving tech industry. Whether you're a seasoned professional looking to upskill or someone new to the tech field, we provide the resources, guidance, and opportunities to help you achieve your career goals.</p>
        <div className="simple-flex">
            {/* <a
              href="/aboutus"
              className="toplevel-button-alt"
            > */}
              <button className='learn-more'><a  href="/aboutus">Learn More</a></button>
           
            </div>

        </div>

    </div>
  )
}

export default AboutUs